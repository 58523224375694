import React from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';
import AnimatedCountUp from './AnimatedCountUp';
export default function AnimatedCountUpDecimal({
  inView,
  unit,
  fontSizeUnit,
  count,
  fontSize,
  duration,
  decimal,
  decimalDuration,
  decimalCount,
  unitSpacing,
  numberColor,
  textColor,
  cellHeight,
  justifyContent,
  letterSpacing,
}) {
  return (
    <Flex
      justifyContent={justifyContent}
      flexDirection={{ base: 'column', lg: 'row' }}
      alignItems={'baseline'}
    >
      <AnimatedCountUp
        count={count}
        fontSize={fontSize}
        duration={duration}
        inView={inView}
        cellHeight={cellHeight}
        numberColor={numberColor}
        letterSpacing={letterSpacing}
      />
      {decimal && (
        <>
          <Box
            fontSize={fontSize}
            color={numberColor}
            mx={1}
            lineHeight='0'
          ></Box>
          <AnimatedCountUp
            count={decimalCount}
            fontSize={fontSize}
            duration={decimalDuration}
            inView={inView}
            numberColor={numberColor}
            cellHeight={cellHeight}
            letterSpacing={letterSpacing}
          />
        </>
      )}
      {unit && (
        <Text fontSize={fontSizeUnit} ml={unitSpacing} color={textColor}>
          {unit}
        </Text>
      )}
    </Flex>
  );
}

import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Stack,
  Text,
  useColorMode,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import Line from '../components/Line';
import ImgTextModule from '../components/elements/ImgTextModule';
import AnimatedCountUpDecimal from '../components/UX/AnimatedCountUpDecimal';
import FadeInAnimation from '../components/UX/FadeInAnimation';
import MaskSlidInAnimation from '../components/UX/MaskSlideInAnimation';
import MagicText from '../components/UX/MagicText';

import RouterLink from '../components/Utility/Routing/RouterLink';
import InViewTrigger from '../components/Utility/UX/InViewTrigger';
import Headerelement from '../components/elements/Headerelement';

const IndexPage = () => {
  const { setColorMode } = useColorMode();

  useEffect(() => {
    setColorMode('light');
  });

  return (
    <>
      <Helmet>
        <title>KDW | Impressum</title>
        <meta
          name='description'
          content='kdw HR bietet Kunden Dienstleistungen von der Personalvermittlung, über qualifizierte Arbeitnehmerüberlassung bis hin zu Management- und Fördermittelberatung im gesamten Personalmanagementbereich. Arbeitnehmer finden bei uns langfristige Karriereoptionen genauso wie neue Herausforderungen vornehmlich im Industrie-, Gewerbe- und Officesegment'
        />
        <link rel='canonical' href='https:/kdw-hr.de' />
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/apple-touch-icon.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='/favicon-32x32.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='/favicon-16x16.png'
        />
        <link rel='manifest' href='/site.webmanifest' />
        <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' />
        <meta name='msapplication-TileColor' content='#da532c' />
        <meta name='theme-color' content='#ffffff' />
      </Helmet>

      <ImgTextModule
        src={
          '478268_Man_Trekking_Mountains_Italy_By_jmg_visuals_Artlist_HD.mp4'
        }
        delay={0.3}
        duration={0.5}
        threshold={0.3}
        initialY={10}
        initialX={10}
        video={true}
        h='65vh'
        mediaOpacity={0.8}
        bgColor='brand.gray.100'
      >
        <Container variant='layoutContainer' mt={8}>
          <Box p={{ base: 0, lg: '8' }}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            ></FadeInAnimation>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.4}
              initialX={0}
              initialY={-10}
            >
              <Text
                color='brand.red'
                fontWeight='normal'
                fontSize={{ base: '5xl', lg: '6xl' }}
              >
                Impressum
              </Text>
            </FadeInAnimation>

            <FadeInAnimation
              threshold={0.4}
              delay={1}
              duration={0.7}
              initialX={0}
              initialY={10}
            >
              <Text
                color='brand.yellow'
                mb={24}
                maxW={{ base: '100%', lg: '50%' }}
                fontSize='2xl'
              >
                Das Rechtliche kurz und bündig.
              </Text>
            </FadeInAnimation>
          </Box>
        </Container>
      </ImgTextModule>

      <Box w='100%'>
        <Container variant='layoutContainer' px={10} py={20}>
          <Box w={{ lg: '50%', base: '100%' }}>
            <Heading size='lg' mb={4}>
              Angaben gem&auml;&szlig; &sect; 5 TMG
            </Heading>
            <p>
              kdw HR GmbH &amp; Co. KG
              <br />
              Ulmenstrasse 21
              <br />
              26384 Wilhelmshaven
            </p>

            <p>
              Handelsregister: HRA 201822
              <br />
              Registergericht: Registergericht Oldenburg
            </p>
            <br />
            <p>
              <strong>Vertreten durch:</strong>
              <br />
              kdw Verwaltungs Gmbh
            </p>

            <p>
              Diese vertreten durch:
              <br />
              Silvio Köhler, Nils Dykstra, Maik Wieting
            </p>

            <p>
              Handelsregister: HRB 202654
              <br />
              Registergericht: AG Oldenburg
            </p>

            <Heading size={'lg'} mt={4}>
              Kontakt
            </Heading>
            <p>
              Telefon: 04421 809900
              <br />
              E-Mail: whv@kdw-hr.de
            </p>

            <Heading size={'lg'} mt={4}>
              Umsatzsteuer-ID
            </Heading>
            <p>
              Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a
              Umsatzsteuergesetz:
              <br />
              DE 262248217
            </p>

            <Heading size={'lg'} mt={4}>
              Redaktionell verantwortlich
            </Heading>
            <p>Nils Dykstra</p>

            <Heading mt={4} size={'lg'} my={4}>
              EU-Streitschlichtung
            </Heading>
            <p>
              Die Europ&auml;ische Kommission stellt eine Plattform zur
              Online-Streitbeilegung (OS) bereit:{' '}
              <a
                href='https://ec.europa.eu/consumers/odr/'
                target='_blank'
                rel='noopener noreferrer'
              >
                https://ec.europa.eu/consumers/odr/
              </a>
              .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
            </p>
            <Heading mt={4} fontSize={'xl'} my={4}>
              Aufsichtsbehörde
            </Heading>
            <p>
              Agentur für Arbeit Kiel
              <br />
              Projensdorfer Strasse 82
              <br />
              24106 Kiel
            </p>

            <Heading mt={4} size={'lg'} my={4}>
              Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
            </Heading>
            <p>
              Wir sind nicht bereit oder verpflichtet, an
              Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
              teilzunehmen.
            </p>

            <p>
              Quelle: <a href='https://www.e-recht24.de'>eRecht24</a>
            </p>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default IndexPage;
